import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';

import { queryKeys } from '@/types/query-keys';

export const useChatBootstrap = () => {
   const { data: sessionData } = useSession();
   return useQuery({
      queryKey: queryKeys.chatBoostrap,
      queryFn: async () => {
         if (!sessionData?.['cognito:username']) {
            throw new Error(`Missing sessionData['cognito:username']`);
         }

         const response = await webApiClient.chat.bootstrapChatForWelkinPatient(
            {},
         );

         return response.body;
         // return apiClient.bootstrapChat({
         //    cognito_sub: sessionData?.['cognito:username'],
         // });
      },
      enabled: Boolean(sessionData?.['cognito:username']),
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
   });
};
